// Libs
import { useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// Utils
// Services
import ApiServiceV2 from 'services/apiService/apiServiceV2';
// Selectors
import { selectSelectedSubModuleId } from 'components/Modules/selectors';
import {
	selectSelectedProjectId,
	selectSelectedOrderTargetFilterId,
} from 'core/selectors';
// Hooks
import useToastMessage from './useToastMessage';
const initialValuesState = {};
const initialFormState = {
	isFetching: true,
	sending: false,
	title: undefined,
	components: [],
};

const useForm = ({ formId, payload = {} }) => {
	const { reportId } = payload;
	// Select from datasource
	const sendToast = useToastMessage();
	const selectedModuleItemId = useSelector(selectSelectedSubModuleId);
	const selectedProjectId = useSelector(selectSelectedProjectId);
	const selectedOrderTargetFilterId = useSelector(
		selectSelectedOrderTargetFilterId,
	);
	const [form, setForm] = useState(initialFormState);
	const [values, setValues] = useState(initialValuesState);
	const check = !!formId;

	useEffect(() => {
		setValues(prev => ({
			...prev,
			selectedModuleItemId,
			selectedProjectId,
			selectedOrderTargetFilterId,
		}));
	}, [selectedModuleItemId, selectedProjectId, selectedOrderTargetFilterId]);

	// Get form details
	useEffect(() => {
		let isMounted = true;

		(async () => {
			if (!check) return;

			const data = await ApiServiceV2.get({
				url: `/feedManager/v1/form/${JSON.stringify({ formId, reportId })}`,
			});
			if (!isMounted) return;

			setForm(prev => ({
				...prev,
				...data,
				isFetching: false,
			}));
		})();

		return () => {
			isMounted = false;
		};
	}, [formId, reportId]);

	const handleOnSubmitSuccess = () => {
		setValues(initialValuesState);
		setForm(prev => ({ ...prev, sending: false }));
	};

	// Send form
	const submitForm = useCallback(() => {
		if (!check) return;

		setForm(prev => ({
			...prev,
			sending: true,
		}));
		ApiServiceV2.post({
			url: `/feedManager/v1/form/submit/${JSON.stringify({
				formId,
				reportId,
			})}`,
			data: { values },
		})
			.then(() => {
				handleOnSubmitSuccess();
				sendToast({
					message: 'Proceso completado',
					type: 'success',
				});
			})
			.catch(err => {
				setForm(prev => ({
					...prev,
					sending: false,
				}));
				sendToast({
					message: err.message,
					type: 'error',
				});
			});
	}, [formId, reportId, values]);

	// Get processed component sql data
	const getProcessedComponentSqlData = useCallback(
		async ({ componentId, searchValue, paramValues, returnKey }) => {
			if (!check) return null;
			return ApiServiceV2.post({
				url: `/feedManager/v1/form/component/${JSON.stringify({
					formId,
					reportId,
					componentId,
				})}/processedSqlData`,
				data: {
					selectedProjectId,
					selectedOrderTargetFilterId,
					selectedModuleItemId,
					userParams: {
						...paramValues,
						searchValue,
					},
				},
			})
				.then(data => {
					const returnData = data[returnKey];
					delete data[returnKey];
					setValues(prev => ({ ...prev, ...data }));
					return returnData;
				})
				.catch(() => null);
		},
		[
			formId,
			reportId,
			check,
			selectedModuleItemId,
			selectedProjectId,
			selectedOrderTargetFilterId,
		],
	);

	return {
		form,
		values,
		setValues,
		submitForm,
		getProcessedComponentSqlData,
	};
};

export default useForm;
